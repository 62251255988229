@import url("./components/headers/Header.css");
@import url("./components/mainPages/selectType/SelectType.css");
@import url("./components/mainPages/contractorForm/ContractorForm.css");

html,
body {
  scroll-behavior: smooth;
  transition: background-color 0.5s ease;
  ;
}

.primary_btn {
  display: inline-block;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #FF5757 !important;
  border: 1px solid #FF5757;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: 'Inter' !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 1px -1px rgba(0, 0, 0, 0.1);
  transition-property: background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea:where(.css-dev-only-do-not-override-ixblex).ant-input{
min-height: 150px !important;
}

/* Hover state */
.primary_btn:hover {
  background-color: #e64d4d !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}

/* Focus state */
.primary_btn:focus {
  background-color: #e64d4d !important;
  box-shadow: 0 0 0 3px rgba(227, 28, 61, 0.5);
}

/* Active state */
.primary_btn:active {
  background-color: #9b1c34 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}


.swal2-containers.swal2-top-end>.swal2-popup {
  grid-column: 3;
  align-self: start;

  justify-self: center !important;
}

.swal2-title {
  line-height: 1.3em;
}

.swal2-styled.swal2-confirm {
  background-color: #e64d4d !important;
}

.ant-select-selector {
  border-radius: 0 !important;
}

.ant-input,
.ant-picker {
  border-radius: 0 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important
}

.ant-input[type='file'] {

  padding-top: 3.5px !important;
  padding-bottom: 3.5px !important
}

.ant-form-item-control-input {
  border-radius: 0 !important;

}


.ant-input-affix-wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 35px;
  padding: 1px 11px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-form-item-explain-error {
  margin-bottom: 20px;
}
.hero-abt-quote {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.intialValue::before {
  position: relative;
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";

}

.ql-containers {
  height: auto !important;
}

.text-color {
  color: #FF5757;
}


@media (min-width: 768px) {
  .footer {
    margin-left: 0px !important;
  }
}

.ant-space-item {
  gap: 0 !important;
}

.about_head_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-image {
  height: 500px;
  background: rgba(0, 0, 0, 0.79);
}

/* About us page CSS */

.hero-section {
  background-size: cover;
  min-height: 500px;
  background-repeat: no-repeat;
  background-position: center center;


}

.hero-overlay {
  position: relative;
}

.hero-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.hero-abt-sourcify {
  color: #FF5757;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
}

.hero-abt-title {

  font-size: 2.5625rem;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
  color: #f1f1f1;

}

@media only screen and (max-width: 600px) {
  .hero-abt-title {

    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #f1f1f1;
  }

  .hero-abt-sourcify {
    color: #FF5757;
    font-size: 2rem;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .card{
    box-shadow: none;
  }
}


.founder_about_image {
  min-height: 500px;
}

.what_we_do_quote {

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;


}

.what_We_do_title {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;

}


/* Footer */

.footer {
  min-height: 350px;
  padding-top: 2rem;
}
.border_footer{
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
}
.footer h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

}

.footer li {
  margin: 10px 0px;
}

/*save button*/
.save_Btn {
  display: inline-block;
  padding-top: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.75rem;
  margin-top: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 150ms;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 1.375;
  text-transform: uppercase;
  border-radius: 50px;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background: #FF5757 !important;
}

@media (min-width: 768px) {
  .save_Btn {
    width: 25%;
  }

}

@media (max-width: 768px) {
  .hero-abt-title {
    line-height: 30px;
  }

}

.save_Btn:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: #FF5759;
}

.back_btn {
  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 1.375;
  text-transform: uppercase;
  width: 100%;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  color: #FF5757;
  font-weight: 600;
  border: 2px solid #FF5757;
  border-radius: 50px;
}

@media (min-width: 768px) {
  .back_btn {
    width: 25%;
  }
 
  
  
}
@media (max-width: 768px) {
  .brand_text {
    font-size: 50px !important ;
    /* Set maximum width to 1140px for xxl screens */
  }
 
  
  
}



.back_btn:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}



.scrollbar::-webkit-scrollbar {
  width: 1px;
}
.sleak_scrollbar::-webkit-scrollbar {
  width: 1px I !important;
}
.sleak_scrollbar::-webkit-scrollbar-track {
  width: 1px I !important;

}

.font_400{
  font-weight: 400 !important;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff;
  height: 1px !important;
}

.input_radius {
  border-radius: 6px !important;
}
.news_input {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.input_border {
  background: #FFFFFF;
  border: 1px solid #000000 !important;
  border-radius: 6px !important
}

.ant-form-item-label >label{
  font-family: 'Inter' !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}


.ant-select-arrow{
  color: #000000 !important;
}

.Under Review_color{
 background-color:  rgb(202 138 4 ) !important
}


.ant-input-search .ant-input-search-button{
  height: 2.2rem !important;
}

.ant-table-column-sorter-down {
  border: none !important;
}
.ant-table-column-sorter-up{
  border: none !important;
}

.dropdown-menu {
  display: block;
  position: absolute;
  margin-top: 10px;
  top: 100%;
  left: 0;
  width: 250px;
  min-height: auto;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.1);
 padding: 8px;
}

/* Hide dropdown menu by default */
.dropdown-menu {
  display: none;
}

/* Show dropdown menu when parent div is being hovered */
.p-1:hover .dropdown-menu {
  display: block;
}


.padding_6_9{
  padding: 6px 9px !important;
}


.font_24{
  font-size: 24px !important;
}